import React from 'react';

const IconLogo = () => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    width="512.000000pt"
    height="512.000000pt"
    viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet">
    <title>Logo</title>

    <g
      id="Logo"
      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
      fill="#fee715ff"
      stroke="none">
      <path d="M0 2560 l0 -2560 2560 0 2560 0 0 2560 0 2560 -2560 0 -2560 0 0 -2560z m2740 1585 c467 -73 745 -301 842 -691 31 -128 31 -379 -1 -509 -53 -218 -137 -367 -285 -501 -176 -161 -414 -251 -725 -274 -132 -9 -570 2 -593 16 -12 8 -4 -1106 9 -1183 l9 -53 -239 0 -240 0 7 37 c22 129 27 547 23 1723 -3 925 -7 1287 -16 1348 -7 46 -11 85 -9 87 2 2 158 8 348 13 418 12 742 8 870 -13z" />
      <path d="M2097 3813 l-127 -4 2 -642 3 -642 65 -8 c113 -14 518 -13 599 1 159 27 317 101 400 186 102 105 153 254 153 451 0 208 -49 344 -168 463 -87 88 -196 144 -338 173 -97 20 -345 30 -589 22z" />
    </g>
  </svg>
);

export default IconLogo;
